
body{
    margin: 0;
    @include body--font;
    width: 100%;
    height: 100%;
    line-height: 1.4;
    background-color: $body--background-color;

    &.body--index{
        background-color: $uo-foam;
    }
}

h1,h2,h3,h4,h5,h6
{
    @include head--font;
    color: $head--color;

}

a { 
    color: $link--color; 
    text-decoration: none; 
    line-height: inherit; 
    &:hover { 
        color: $link--color--hover; 
        cursor: pointer;
    }
    &:focus{
        color: $link--color--focus; 
        outline: none;
    }
    &:visited{
        color: $link--color--visited;
    }
}

hr{
    border: 0;
    border-bottom: 1px dashed $table--border-color;
    background: $table--background-color;
    margin: 2em 0;
}

li {
    margin: .5em 0;
}

table {
    /* Remove spacing between table cells (from Normalize.css) */
    @include head--font;
    background-color: $table--background-color;
    font-size: 0.875rem;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid $table--border-color;
    margin-top: 1rem;
}

table caption {
    padding: 1rem 0;
    text-align: center;
}

table td,
table th {
    border-left: 1px solid $table--border-color;/*  inner column border */
    border-width: 0 0 0 1px;
    font-size: inherit;
    margin: 0;
    overflow: visible; /*to make ths where the title is really long work*/
    padding: 0.5em 1em; /* cell padding */
}

table thead {
    background-color: $table--background-color--alt;
    border-bottom: 1px solid $table--border-color;
    text-align: left;
    vertical-align: bottom;
}

table td {
    background-color: $table--background-color;
}

/* nth-child selector for modern browsers */
table tr:nth-child(even) td {
    background-color: $table--background-color--alt;
}

/* BORDERED TABLES */
table td {
    border-bottom: 1px solid $table--border-color;
}

table tbody > tr:last-child > td {
    border-bottom-width: 0;
}

.Note, .Example{
    border: 1px solid $box--border-color;
    background-color: $box--background-color;
    padding: .25rem 1rem;
    @include head--font;
    font-size: 0.875rem;  
    margin-top: 1rem;
}

.Example{
    font-style: italic;
}

main.index{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
    section{
        flex: 1;
        align-self: center;
        text-align: center;
    }
    
    .symbolbox{
        max-width: 70vh;
        margin: auto;
        padding: 2rem;
    }

    h1{
        color: $uo-sea-light;
        font-family: 'Alike Angular', serif;
        font-weight: normal;
    }
}

main.plain{
    display: flex;
    align-items: center;
    justify-content: center;
    
    section{
        flex-basis: 610px;
    }
    
    .symbolbox{
        max-width: 150px;
        margin: auto;
        padding: 2rem;
    }
}
// Utilities

img,
object,
embed {
    max-width: 100%;
    height: auto; }

object,
embed {
    height: 100%; }

img {
    -ms-interpolation-mode: bicubic; 
    display: inline-block;
    vertical-align: middle; 
}
