$uo-paper: #EEF5DB;
$uo-foam: #e0e1dd;
$uo-sea-light: #778da9;
$uo-sea-dark: #415a77;
$uo-ink-light: #1b263b;
$uo-ink-dark: #0d1b2a;
$uo-red: #DB3A34;
$uo-wine: #982649;
$uo-green: #6D9F71;

@mixin head--font() {
    font-family: Verdana, sans-serif;
}

@mixin body--font() {
    font-family: Georgia, serif;
}

@mixin menu--font() {
    font-family: Verdana, serif;
}


$body--background-color: $uo-foam;
$body--color: $uo-ink-dark;

$head--color: $uo-ink-light;

$link--color: $uo-sea-light;
$link--color--hover: $uo-red;
$link--color--visited: $uo-green;
$link--color--focus: $uo-red;

$table--background-color: $uo-foam;
$table--background-color--alt: white;
$table--border-color: $uo-ink-dark;

$box--border-color: $uo-green;
$box--background-color: $uo-paper;

